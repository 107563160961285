// ids for movies to be used by MOTD - Movies.js
export const motdIds = [136, 14029, 11347, 11906, 141, 193726, 176, 3176, 766, 82507,
                        75761, 16296, 27390, 10185, 33516, 923, 37169, 348, 679, 40364,
                        2671, 814, 13220, 747, 22970, 4232, 9003, 38783, 11905, 22244,
                        1690];

// tvShw object for random diplay on TV.js
export const tvShow = [
    {
        showName: 'Buffy',
        id: '-1v_q6TWAL4',
        start: 112
    },
    {
        showName: 'American Horror Story',
        id: '-9KZr2Vn7CQ',
        start: 50
    },
    {
        showName: 'Supernatural',
        id: 't-775JyzDTk',
        start: 62
    },
    {
        showName: 'Haunting of Hill House',
        id: '3eqxXqJDmcY',
        start: 57
    },
    {
        showName: 'Tales Form the Crypt',
        id: 'A3s8xAVkpDc',
        start: 50
    },
    {
        showName: 'True Blood',
        id: 'hE8wwYzKJOs',
        start: 120
    },
    {
        showName: 'Night Gallery',
        id: '5rVLeogPTI4',
        start: 120
    },
    {
        showName: 'Walking Dead',
        id: 'qn1kXp9T9EY',
        start: 92
    },
    {
        showName: 'Twilight Zone',
        id: '5ymjp2uIBws',
        start: 25
    },
    {
        showName: 'Munsters',
        id: '8TTIqK9nFH8',
        start: 25
    },
    {
        showName: 'Addams Family',
        id: 'ZZ5IWRz78DY',
        start: 15
    },
    {
        showName: 'Dark Shadows',
        id: 'xvQQ55O2Uvk',
        start: 196
    },
    {
        showName: 'Tales From the Darkside',
        id: 'Mioevxb2CfA',
        start: 38
    },
    {
        showName: 'Friday the 13th: the Series',
        id: 'pYquKiVJAlE',
        start: 10
    },
    {
        showName: 'Freddy\'s Nightmares',
        id: 'Us_deD2MOp0',
        start: 2
    }
]
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Movies from "./components/Movies";
import Home from "./components/Home";
import TV from "./components/Tv";
import Games from "./components/Games";
import Books from "./components/Books";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/movies" element={<Movies />} />
          <Route path="/tv" element={<TV />} />
          <Route path="/games" element={<Games />} />
          <Route path="/books" element={<Books />} />
          <Route path="/sites" element={<Home />} />
          <Route path="/reviews" element={<Home />} />

          {/*     
          <Route path="/sites" component={Sites} />   
          <Route path="/reviews" component={Reviews} />   
          <Route path="/archives" component={Archives} /> 
          <Route path="/ticTacDeath" component={TicTacDeath} />
          <Route path="/hangman" component={Hangman} />
          <Route path="/whackZombie" component={WhackZombie} />
          <Route path="/ghostFinder" component={GhostFinder} />
          <Route path="/soulMatch" component={SoulMatch} />
          <Route path="/franksLab" component={FranksLab} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
